import { ValidationTypes } from "constants/WidgetValidation";
import { get } from "lodash";
import { ButtonVariantTypes } from "widgets/ZButtonWidget/constants";
import { getBasePropertyPath } from "widgets/TableWidget/widget/propertyUtils";
import type { AppBarWidgetProps } from "../index";
import { MenuItemsSource } from "../../constants";
import {
  updateMenuItemsSource,
  hiddenNotMenuActionDynamic,
  hiddenNotMenuActionStatic,
} from "./propertyUtils";
import configureMenuItemsConfig from "../../../PageHeaderWidget/widget/propertyConfig/childPanels/configureMenuItemsConfig";
import { sourceDataArrayValidation } from "widgets/MenuButtonWidget/validations";
import { AutocompleteDataType } from "utils/autocomplete/AutocompleteDataType";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";

export default {
  editableTitle: true,
  titlePropertyName: "label",
  panelIdPropertyName: "id",
  updateHook: (props: any, propertyPath: string, propertyValue: string) => {
    return [
      {
        propertyPath,
        propertyValue,
      },
    ];
  },
  contentChildren: [
    {
      sectionName: "Action",
      children: [
        {
          propertyName: "type",
          helpText: "Type of action",
          label: "Type",
          controlType: "DROP_DOWN",
          options: [
            {
              label: "Typography",
              value: "Typography",
            },
            {
              label: "Image",
              value: "Image",
            },
            {
              label: "Button",
              value: "Button",
            },
            {
              label: "Menu",
              value: "Menu",
            },
          ],
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: ["Typography", "Image", "Button", "Menu"],
            },
          },
        },
        {
          propertyName: "menuItemsSource",
          helpText: "Sets the source for the menu items",
          label: "Menu items source",
          controlType: "ICON_TABS",
          defaultValue: MenuItemsSource.STATIC,
          fullWidth: true,
          options: [
            {
              label: "Static",
              value: MenuItemsSource.STATIC,
            },
            {
              label: "Dynamic",
              value: MenuItemsSource.DYNAMIC,
            },
          ],
          isJSConvertible: false,
          isBindProperty: false,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          updateHook: updateMenuItemsSource,
          dependencies: ["sourceData", "configureMenuItems"],
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Typography", "Image", "Button"].includes(type);
          },
        },
        {
          helpText: "Takes in an array of items to display the menu items.",
          propertyName: "sourceData",
          label: "Source data",
          controlType: "INPUT_TEXT",
          placeholderText: "{{Query1.data}}",
          inputType: "ARRAY",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.FUNCTION,
            params: {
              fn: sourceDataArrayValidation,
              expected: {
                type: "Array of values",
                example: `['option1', 'option2'] | [{ "label": "label1", "value": "value1" }]`,
                autocompleteDataType: AutocompleteDataType.ARRAY,
              },
            },
          },
          evaluationSubstitutionType:
            EvaluationSubstitutionType.SMART_SUBSTITUTE,
          hidden: hiddenNotMenuActionDynamic,
          dependencies: ["menuItemsSource"],
        },
        {
          helpText: "Configure how each menu item will appear.",
          propertyName: "configureMenuItems",
          controlType: "OPEN_CONFIG_PANEL",
          buttonConfig: {
            label: "Configure",
            icon: "settings-2-line",
          },
          label: "Configure menu items",
          isBindProperty: false,
          isTriggerProperty: false,
          hidden: hiddenNotMenuActionDynamic,
          dependencies: ["menuItemsSource", "sourceData"],
          panelConfig: configureMenuItemsConfig,
        },
        {
          hidden: hiddenNotMenuActionStatic,
          dependencies: ["type", "menuItemsSource"],
          helpText: "Menu items",
          propertyName: "menuItems",
          controlType: "MENU_ITEMS",
          label: "Menu items",
          isBindProperty: false,
          isTriggerProperty: false,
          panelConfig: {
            editableTitle: true,
            titlePropertyName: "label",
            panelIdPropertyName: "id",
            updateHook: (
              props: any,
              propertyPath: string,
              propertyValue: string,
            ) => {
              return [
                {
                  propertyPath,
                  propertyValue,
                },
              ];
            },
            contentChildren: [
              {
                sectionName: "Label",
                children: [
                  {
                    propertyName: "label",
                    helpText: "Sets the label of a menu item",
                    label: "Text",
                    controlType: "INPUT_TEXT",
                    placeholderText: "Enter label",
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.TEXT },
                  },
                ],
              },
              {
                sectionName: "General",
                children: [
                  {
                    propertyName: "isVisible",
                    helpText: "Controls the visibility of menu item",
                    label: "Visible",
                    controlType: "SWITCH",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: {
                      type: ValidationTypes.BOOLEAN,
                    },
                  },
                  {
                    propertyName: "isDisabled",
                    helpText: "Disables menu item",
                    label: "Disabled",
                    controlType: "SWITCH",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: {
                      type: ValidationTypes.BOOLEAN,
                    },
                  },
                ],
              },
              {
                sectionName: "Events",
                children: [
                  {
                    helpText: "when the menu item is clicked",
                    propertyName: "onClick",
                    label: "onClick",
                    controlType: "ACTION_SELECTOR",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: true,
                  },
                ],
              },
            ],
            styleChildren: [
              {
                sectionName: "Icon",
                children: [
                  {
                    propertyName: "icon",
                    label: "Icon",
                    controlType: "ZICON_SELECT",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.TEXT },
                  },
                  {
                    propertyName: "startIcon",
                    label: "StartIcon",
                    controlType: "ZICON_SELECT",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.TEXT },
                  },
                  {
                    propertyName: "endIcon",
                    label: "EndIcon",
                    controlType: "ZICON_SELECT",
                    isJSConvertible: true,
                    isBindProperty: true,
                    isTriggerProperty: false,
                    validation: { type: ValidationTypes.TEXT },
                  },
                ],
              },
            ],
          },
        },
        {
          propertyName: "label",
          helpText: "Sets the label of a action item",
          label: "Label",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter label",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "value",
          helpText: "Sets the value of a aciton item",
          label: "Value",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter Value",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Button", "Menu"].includes(type);
          },
        },
        {
          propertyName: "badgeContent",
          helpText: "Sets the badgeContent",
          label: "Badge Content",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter Value",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.NUMBER },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Typography", "Image"].includes(type);
          },
        },
        {
          propertyName: "imageWidth",
          label: "Image Width",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter Value",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.NUMBER },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Typography", "Button", "Menu"].includes(type);
          },
        },
        {
          propertyName: "imageHeight",
          label: "Image Height",
          controlType: "INPUT_TEXT",
          placeholderText: "Enter Value",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.NUMBER },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Typography", "Button", "Menu"].includes(type);
          },
        },
        {
          propertyName: "icon",
          label: "Icon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Typography", "Image"].includes(type);
          },
        },
        {
          propertyName: "startIcon",
          label: "StartIcon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Image"].includes(type);
          },
        },
        {
          propertyName: "endIcon",
          label: "EndIcon",
          controlType: "ZICON_SELECT",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Image"].includes(type);
          },
        },
      ],
    },
    {
      sectionName: "General",
      children: [
        {
          propertyName: "isVisible",
          helpText: "Controls the visibility of the widget",
          label: "Visible",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "isDisabled",
          helpText: "Disables input to the widget",
          label: "Disabled",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
      ],
    },
    {
      sectionName: "Events",
      children: [
        {
          propertyName: "onClick",
          label: "onClick",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isTriggerProperty: true,
          isBindProperty: true,
          hidden: (props: AppBarWidgetProps, propertyPath: string) => {
            const property = getBasePropertyPath(propertyPath);
            const type = get(props, `${property}.type`, "");
            return ["Typography", "Image", "Menu"].includes(type);
          },
        },
      ],
    },
  ],
  styleChildren: [
    {
      sectionName: "Button Style",
      hidden: (props: AppBarWidgetProps, propertyPath: string) => {
        const type = get(props, `${propertyPath}.type`, "");
        return ["Typography", "Image"].includes(type);
      },
      children: [
        {
          propertyName: "variant",
          label: "Button variant",
          controlType: "ICON_TABS",
          fullWidth: true,
          helpText: "Sets the variant of the button",
          options: [
            {
              label: "Contained",
              value: ButtonVariantTypes.CONTAINED,
            },
            {
              label: "Outlined",
              value: ButtonVariantTypes.OUTLINED,
            },
            {
              label: "Text",
              value: ButtonVariantTypes.TEXT,
            },
          ],
          defaultValue: ButtonVariantTypes.CONTAINED,
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: [
                ButtonVariantTypes.CONTAINED,
                ButtonVariantTypes.TEXT,
                ButtonVariantTypes.TERTIARY,
                ButtonVariantTypes.OUTLINED,
              ],
              default: ButtonVariantTypes.CONTAINED,
            },
          },
        },
      ],
    },
    {
      sectionName: "Typography Style",
      hidden: (props: AppBarWidgetProps, propertyPath: string) => {
        const type = get(props, `${propertyPath}.type`, "");
        return ["Button", "Image", "Menu"].includes(type);
      },
      children: [
        {
          propertyName: "fontFamily",
          label: "Font family",
          helpText: "Controls the font family being used",
          controlType: "DROP_DOWN",
          options: [
            {
              label: "System Default",
              value: "System Default",
            },
            {
              label: "Monospace",
              value: "monospace",
            },
            {
              label: "Sans Serif",
              value: "sans-serif",
            },
            {
              label: "Serif",
              value: "serif",
            },
            {
              label: "Cursive",
              value: "cursive",
            },
            {
              label: "Fantasy",
              value: "fantasy",
            },
            {
              label: "Open Sans",
              value: "Open Sans",
            },
          ],
          defaultValue: "System Default",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
          },
        },
        {
          propertyName: "fontSize",
          label: "Font size",
          helpText: "Control the font size of the label associated",
          defaultValue: "0.875rem",
          controlType: "DROP_DOWN",
          options: [
            {
              label: "XS",
              value: "0.688rem",
              subText: "0.688rem",
            },
            {
              label: "S",
              value: "0.875rem",
              subText: "0.875rem",
            },
            {
              label: "M",
              value: "1rem",
              subText: "1rem",
            },
            {
              label: "L",
              value: "1.25rem",
              subText: "1.25rem",
            },
            {
              label: "XL",
              value: "1.875rem",
              subText: "1.875rem",
            },
            {
              label: "XXL",
              value: "3rem",
              subText: "3rem",
            },
            {
              label: "3XL",
              value: "3.75rem",
              subText: "3.75rem",
            },
          ],
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "fontStyle",
          label: "Emphasis",
          helpText: "Control if the label should be bold or italics",
          controlType: "BUTTON_GROUP",
          options: [
            {
              icon: "text-bold",
              value: "BOLD",
            },
            {
              icon: "text-italic",
              value: "ITALIC",
            },
          ],
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
        {
          propertyName: "fontWeight",
          helpText: "Enter value for font weight",
          label: "Font weight",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.NUMBER },
        },
      ],
    },
    {
      sectionName: "Color",
      hidden: (props: AppBarWidgetProps, propertyPath: string) => {
        const type = get(props, `${propertyPath}.type`, "");
        return ["Image"].includes(type);
      },
      children: [
        {
          propertyName: "color",
          label: "Color",
          controlType: "COLOR_PICKER",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
        },
      ],
    },
    // {
    //   sectionName: "Border and shadow",
    //   children: [
    //     {
    //       propertyName: "borderRadius",
    //       label: "Border radius",
    //       helpText: "Rounds the corners of the icon button's outer border edge",
    //       controlType: "BORDER_RADIUS_OPTIONS",
    //       isJSConvertible: true,
    //       isBindProperty: true,
    //       isTriggerProperty: false,
    //       validation: { type: ValidationTypes.TEXT },
    //       defaultValue: "none",
    //     },
    //     {
    //       propertyName: "boxShadow",
    //       label: "Box shadow",
    //       helpText:
    //         "Enables you to cast a drop shadow from the frame of the widget",
    //       controlType: "BOX_SHADOW_OPTIONS",
    //       isJSConvertible: true,
    //       isBindProperty: true,
    //       isTriggerProperty: false,
    //       validation: { type: ValidationTypes.TEXT },
    //       defaultValue: "none",
    //     },
    //   ],
    // },
  ],
};
