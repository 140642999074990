import React, { type ReactNode } from "react";
import { Tabs, Tab } from "zds";
import type { ComponentProps } from "../../BaseComponent";
import {
  generateClassName,
  getCanvasClassName,
} from "../../../utils/generators";
import styled from "styled-components";
import { scrollCSS } from "../../WidgetUtils";

const ScrollCanvas = styled.div<{ $shouldScrollContents: boolean }>`
  overflow: hidden;
  ${(props) => (props.$shouldScrollContents ? scrollCSS : ``)}
  width: 100%;
`;
const TabContainer = styled.div`
  padding-left: 10px;
  margin-top: -10px;
  && .MuiTabs-root {
    min-height: 41px;
    height: 41px;
  }
`;
const Container = styled.div`
  margin-left: -6px;
`;

function ZTabsComponent(props: ZTabsComponentProps) {
  const { onTabChange, tabs } = props;

  return (
    <Container>
      <TabContainer>
        <Tabs
          a11yLabel="ZTabs"
          dsOnChange={(event, value) => onTabChange(value)}
          e2e="ZTabs"
          value={props.activeTab}
        >
          {tabs.map((tab) => (
            <Tab
              aria-controls={tab.widgetId}
              e2e={tab.widgetId}
              id={tab.widgetId}
              key={tab.widgetId}
              label={tab.label}
              value={tab.label}
            />
          ))}
        </Tabs>
      </TabContainer>
      <ScrollCanvas
        $shouldScrollContents={!!props.shouldScrollContents && !props.$noScroll}
        className={`${
          props.shouldScrollContents ? getCanvasClassName() : ""
        } ${generateClassName(props.widgetId)}`}
      >
        {props.children}
      </ScrollCanvas>
    </Container>
  );
}

export interface ZTabsComponentProps extends ComponentProps {
  tabs: Array<{
    id: string;
    label: string;
    widgetId: string;
    isVisible?: boolean;
  }>;
  children?: ReactNode;
  shouldScrollContents?: boolean;
  $noScroll: boolean;
  onTabChange: (activeTab: string) => void;
  selectedTabWidgetId: string;
  activeTab: string;
}

export default ZTabsComponent;
