import React from "react";
import styled from "styled-components";

import {
  Button,
  Toggle,
  Checkbox,
  Dropdown,
  DropdownItem,
  Icon,
  RadioGroup,
  Radio,
  Select,
  SelectItem,
} from "zds";
import type { HeaderActionItem, MenuItem } from "../constants";
import {
  Grid,
  IconButton,
  Menu,
  MenuItem as MUIMenuItem,
  FormLabel,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { ButtonVariantTypes } from "../../ZButtonWidget/constants";
import { MenuItemsSource } from "../constants";
import { isArray, orderBy } from "lodash";
import { FontStyleTypes } from "../../../constants/WidgetConstants";

const Wrapper = styled.div<{ buttonColor?: string; isDisabled?: boolean }>`
  .Mui-checked {
    color: ${({ buttonColor, isDisabled }) =>
      isDisabled
        ? "inherit"
        : buttonColor
          ? `${buttonColor} !important`
          : "inherit"};
  }
`;

interface PageHeaderActionsProps {
  actions?: Record<string, HeaderActionItem>;
  maxHeaderActionDisplay?: number;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
}

const getVisibleItems = (action: HeaderActionItem) => {
  const { configureMenuItems, menuItems, menuItemsSource, sourceData } = action;
  if (menuItemsSource === MenuItemsSource.STATIC) {
    const visibleItems = Object.keys(menuItems)
      .map((itemKey) => menuItems[itemKey])
      .filter((item) => item.isVisible === true);

    return orderBy(visibleItems, ["index"], ["asc"]);
  } else if (
    menuItemsSource === MenuItemsSource.DYNAMIC &&
    isArray(sourceData) &&
    sourceData?.length &&
    configureMenuItems?.config
  ) {
    const { config } = configureMenuItems;
    const getValue = (propertyName: keyof MenuItem, index: number) => {
      const value = config[propertyName];

      if (isArray(value)) {
        return value[index];
      }

      return value ?? null;
    };

    const visibleItems = sourceData
      .map((item, index) => ({
        ...item,
        id: index.toString(),
        isVisible: getValue("isVisible", index),
        isDisabled: getValue("isDisabled", index),
        index: index,
        widgetId: "",
        label: getValue("label", index),
        onClick: config?.onClick,
        icon: getValue("icon", index),
        startIcon: getValue("startIcon", index),
        endIcon: getValue("endIcon", index),
      }))
      .filter((item) => item.isVisible === true);

    return visibleItems;
  }

  return [];
};

interface Option {
  label: string | undefined;
  value: any;
}

interface OptionProps {
  [key: string]: any;
}

const getOptions = (action: HeaderActionItem): Option[] => {
  let labels: (string | undefined)[] = [];
  let values: any[] = [];
  const sourceData = action.options || [];

  if (typeof action.optionLabel === "string") {
    labels = sourceData.map((d) => d[action.optionLabel as keyof OptionProps]);
  } else if (Array.isArray(action.optionLabel)) {
    labels = action.optionLabel;
  }

  if (typeof action.optionValue === "string") {
    values = sourceData.map((d) => d[action.optionValue as keyof OptionProps]);
  } else if (Array.isArray(action.optionValue)) {
    values = action.optionValue;
  }

  return sourceData.map((d, i) => ({
    label: labels[i],
    value: values[i],
  }));
};

const Action = ({
  action,
  buttonClickHandler,
  handleOnchange,
}: {
  action: HeaderActionItem;
  buttonClickHandler: (
    onClick: string | undefined,
    action?: HeaderActionItem,
    index?: number,
  ) => void;
  handleOnchange: (
    onClick: string | undefined,
    propertyPath?: string,
    value?: any,
  ) => void;
}) => {
  const handleChange = (event: any, value: any) => {
    handleOnchange(action.onChange, `headerActions.${action.id}.value`, value);
  };

  const commonProps = {
    disabled: action.isDisabled,
    dsOnChange: (event: any, value: any) => handleChange(event, value),
    label: action.label,
    name: action.label,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const dsOnClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? `menu-popover-${action.label}` : "";

  const styles = action.labelStyle?.split(",");
  const getCommonStyles = (
    action: HeaderActionItem,
    styles: string | string[] | undefined,
  ) => ({
    ".Mui-checked": {
      color: action.isDisabled ? undefined : `${action.buttonColor} !important`,
    },
    ".MuiFormControlLabel-label": {
      fontSize: action.labelTextSize
        ? `${action.labelTextSize} !important`
        : "14px",
      fontWeight: styles?.includes(FontStyleTypes.BOLD) ? "600" : "normal",
      fontStyle: styles?.includes(FontStyleTypes.ITALIC) ? "italic" : "",
    },
  });

  switch (action.actionType) {
    case "CHECKBOX":
      return (
        <Grid item sx={getCommonStyles(action, styles)}>
          <Checkbox
            checked={action.value}
            value={action.value}
            {...commonProps}
          />
        </Grid>
      );
    case "MENU":
      const visibleMenuItems = getVisibleItems(action);
      return (
        <Grid item>
          <Button
            a11yDescribedby={id}
            a11yHaspopup
            disabled={action.isDisabled}
            dsOnClick={handleClick}
            endIcon={action.endIcon}
            icon={action.icon}
            startIcon={action.startIcon}
            sx={{
              backgroundColor:
                (action.variant === ButtonVariantTypes.CONTAINED ||
                  action.variant === undefined) &&
                !action.icon &&
                !action.isDisabled
                  ? `${action.buttonColor} !important`
                  : undefined,
              color:
                action.variant !== ButtonVariantTypes.CONTAINED &&
                action.variant !== undefined
                  ? action.buttonColor
                  : undefined,
              borderColor:
                action.variant !== ButtonVariantTypes.CONTAINED &&
                action.variant !== undefined
                  ? action.buttonColor
                  : undefined,
              fontFamily: "inherit",
              fontWeight: 400,
              textTransform: "unset",
            }}
            variant={action.variant}
          >
            {action.label}
          </Button>
          <Dropdown
            a11yId={id}
            anchorEl={anchorEl}
            dsOnClose={dsOnClose}
            e2e="myDropdown"
            open={open}
          >
            {visibleMenuItems &&
              visibleMenuItems.map((item, index) => (
                <DropdownItem
                  dsOnClick={() => {
                    dsOnClose();
                    if (item.isDisabled) {
                      return;
                    }
                    buttonClickHandler(item.onClick, action, index);
                  }}
                  key={`menu-item-${action.label}-${index}`}
                >
                  {item.startIcon && <Icon body={item.startIcon} />}
                  {item.label}
                  {item.endIcon && <Icon body={item.endIcon} />}
                </DropdownItem>
              ))}
          </Dropdown>
        </Grid>
      );
    case "RADIO":
      return (
        <Grid
          item
          sx={{
            ".Mui-checked": {
              color: action.isDisabled
                ? undefined
                : `${action.buttonColor} !important`,
            },
          }}
        >
          <RadioGroup
            row
            value={action.value || action.defaultSelectedOption}
            {...commonProps}
          >
            {action.options &&
              action.options.length > 0 &&
              action.options.map((option) => (
                <Radio
                  key={option.value}
                  label={option.label}
                  value={option.value}
                />
              ))}
          </RadioGroup>
        </Grid>
      );
    case "SELECT":
      const options = getOptions(action);
      return (
        <Grid item>
          <Grid
            alignItems="center"
            alignSelf="flex-start"
            columnSpacing={1}
            container
            justifyContent="flex-start"
          >
            <Grid item>
              <FormLabel
                sx={{
                  fontWeight: styles?.includes(FontStyleTypes.BOLD)
                    ? "600"
                    : "normal",
                  marginBottom: "unset",
                  fontStyle: styles?.includes(FontStyleTypes.ITALIC)
                    ? "italic"
                    : "",
                  fontSize: action.labelTextSize,
                }}
              >
                {action.label}:
              </FormLabel>
            </Grid>
            <Grid item sx={{ marginTop: "-5px" }}>
              <Select
                dsOnChange={(event) => handleChange(event, event.target.value)}
                sx={{
                  ".MuiSelect-select": {
                    padding: "8px 10px 6px",
                    width: `${action.selectWidth || 100}px`,
                    lineHeight: "24px",
                  },
                  boxShadow: action.boxShadow,
                  borderRadius: action.borderRadius,
                }}
                value={action.value || action.defaultSelectedOption || ""}
              >
                {options.length > 0 &&
                  options.map((option, index) => (
                    <SelectItem
                      key={`${option.value}-${index}`}
                      value={option.value}
                    >
                      {option.label}
                    </SelectItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      );
    case "TOGGLE":
      return (
        <Grid item sx={getCommonStyles(action, styles)}>
          <Toggle
            checked={action.value}
            {...commonProps}
            labelPlacement={action.labelPlacement}
          />
        </Grid>
      );
    case "BUTTON":
    default:
      return (
        <Grid item>
          <Button
            disabled={action.isDisabled}
            dsOnClick={() => buttonClickHandler(action.onClick)}
            endIcon={action.endIcon}
            icon={action.icon}
            startIcon={action.startIcon}
            sx={{
              backgroundColor:
                (action.variant === ButtonVariantTypes.CONTAINED ||
                  action.variant === undefined) &&
                !action.icon &&
                !action.isDisabled
                  ? `${action.buttonColor} !important`
                  : undefined,
              color:
                action.variant !== ButtonVariantTypes.CONTAINED &&
                action.variant !== undefined
                  ? action.buttonColor
                  : undefined,
              borderColor:
                action.variant !== ButtonVariantTypes.CONTAINED &&
                action.variant !== undefined
                  ? action.buttonColor
                  : undefined,
              fontFamily: "inherit",
              fontWeight: 400,
              textTransform: "unset",
            }}
            variant={action.variant}
          >
            {action.label}
          </Button>
        </Grid>
      );
  }
};

export const PageHeaderActions = ({
  actions = {},
  buttonClickHandler,
  handleOnchange,
  maxHeaderActionDisplay = 2,
}: PageHeaderActionsProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid
      alignItems="center"
      alignSelf="flex-start"
      columnSpacing={1.5}
      container
      item
      justifyContent="flex-end"
      marginTop={
        Object.values(actions).length > maxHeaderActionDisplay ? "0px" : "2px"
      }
      // sx={{ fontFamily: "inherit" }}
      // xs="auto"
    >
      {Object.values(actions)
        .slice(0, maxHeaderActionDisplay)
        .map((action, index) => (
          <Action
            action={action}
            buttonClickHandler={buttonClickHandler}
            handleOnchange={handleOnchange}
            key={`headeraction-${index}`}
          />
        ))}
      {Object.values(actions).length > maxHeaderActionDisplay && (
        <Grid item>
          <IconButton
            aria-controls="long-menu"
            aria-haspopup="true"
            aria-label="more-actions"
            // data-e2e={e2e ? `${e2e}-more-button` : undefined}
            onClick={handleClick}
            // sx={{ height: "36.5px" }}
          >
            <MoreVert />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            // data-e2e={e2e ? `${e2e}-more-menu` : undefined}
            id="long-menu"
            keepMounted
            onClose={handleClose}
            open={open}
          >
            {actions &&
              Object.values(actions).map(
                (action, index) =>
                  index >= maxHeaderActionDisplay && (
                    <MUIMenuItem
                      data-e2e={action.id}
                      disabled={action.isDisabled}
                      divider={action.divider}
                      // href={action.href}
                      key={action.id}
                      onClick={() => buttonClickHandler(action.onClick)}
                      selected={false}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      {action.actionType === "BUTTON" && action.startIcon && (
                        <Icon body={action.startIcon} />
                      )}
                      {action.actionType === "BUTTON" && action.label}
                      {action.actionType === "BUTTON" && action.endIcon && (
                        <Icon body={action.endIcon} />
                      )}
                      {action.actionType === "TOGGLE" && (
                        <Wrapper
                          buttonColor={action.buttonColor}
                          isDisabled={action.isDisabled}
                        >
                          <Toggle
                            checked={action.value}
                            disabled={action.isDisabled}
                            dsOnChange={(event, value) => {
                              handleOnchange(
                                action.onChange,
                                `headerActions.${action.id}.value`,
                                value,
                              );
                            }}
                            label={action.label}
                          />
                        </Wrapper>
                      )}
                      {action.actionType === "CHECKBOX" && (
                        <Wrapper
                          buttonColor={action.buttonColor}
                          isDisabled={action.isDisabled}
                        >
                          <Checkbox
                            checked={action.value}
                            disabled={action.isDisabled}
                            dsOnChange={(event, value) => {
                              handleOnchange(
                                action.onChange,
                                `headerActions.${action.id}.value`,
                                value,
                              );
                            }}
                            label={action.label}
                          />
                        </Wrapper>
                      )}
                      {action.actionType === "RADIO" && (
                        <Wrapper
                          buttonColor={action.buttonColor}
                          isDisabled={action.isDisabled}
                        >
                          <RadioGroup
                            disabled={action.isDisabled}
                            dsOnChange={(event, value) => {
                              handleOnchange(
                                action.onChange,
                                `headerActions.${action.id}.value`,
                                value,
                              );
                            }}
                            label={action.label}
                            value={action.value || action.defaultSelectedOption}
                          >
                            {action.options &&
                              action.options.length > 0 &&
                              action.options.map((option) => (
                                <Radio
                                  key={option.value}
                                  label={option.label}
                                  value={option.value}
                                />
                              ))}
                          </RadioGroup>
                        </Wrapper>
                      )}
                      {action.actionType === "SELECT" && (
                        <Select
                          disabled={action.isDisabled}
                          dsOnChange={(event) => {
                            handleOnchange(
                              action.onChange,
                              `headerActions.${action.id}.value`,
                              event.target.value,
                            );
                          }}
                          label={action.label}
                          sx={{
                            ".MuiSelect-select": {
                              padding: "6px 10px",
                              width: `${action.selectWidth || 100}px`,
                            },
                            boxShadow: action.boxShadow,
                            borderRadius: action.borderRadius,
                          }}
                          value={
                            action.value || action.defaultSelectedOption || ""
                          }
                        >
                          {action.options &&
                            getOptions(action).length > 0 &&
                            getOptions(action).map((option, index) => (
                              <SelectItem
                                key={`${option.value}-${index}`}
                                value={option.value}
                              >
                                {option.label}
                              </SelectItem>
                            ))}
                        </Select>
                      )}
                    </MUIMenuItem>
                  ),
              )}
          </Menu>
        </Grid>
      )}
    </Grid>
  );
};
